import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import Modal from "react-modal";
import urljoin from "url-join";

import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import {
  renderCancelButton,
  renderButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";
import UiToggle from "@shared/UiToggle";
import { useTicketTypes } from "@shared/hooks/useTickets";


const TicketingManageTicketsModal = (props) => {
  const {
    apiRoot,
    event,
    modalVisible,
    participantId = null,
    resetModal
  } = props;
  const [fetched, setFetched] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [showAssignedTickets, setShowAssignedTickets] = useState(true);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const ticketTypes = useTicketTypes(apiRoot, event.id);
  const [assignedTickets, setAssignedTickets] = useState([]);

  useEffect(() => {
    const fetchParticipant = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/participants", `/${participantId}`));
        setParticipant(result.data.participant);
        setAssignedTickets(result.data.participant.ticketing_participant_tickets);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchParticipant();
  }, [apiRoot, participantId]);

  Modal.setAppElement("#root");

  const attemptAssignTicket = (participant_id, ticket_type_gid) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/ticketing/participant_tickets"),
      method: "POST",
      data: {
        event_participant_id: participant_id,
        ticket_type_gid: ticket_type_gid
      }
    })
      .then((response) => {
        if (response.data.error == null) {
          alertSuccess("Ticket Assigned");
          setAssignedTickets([...assignedTickets, response.data.ticket]);
        } else {
          alertError(`Unable to save ticket type: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const attemptUnassignTicket = (participant_ticket_id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/ticketing/participant_tickets/${participant_ticket_id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error == null) {
          alertSuccess("Ticket Removed");
          setAssignedTickets(assignedTickets.filter((x) => x.id != participant_ticket_id));
        } else {
          alertError(`Unable to remove ticket: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const removeTicket = (id) => {
    attemptUnassignTicket(id);
  }

  const assignTicket = (gid) => {
    attemptAssignTicket(participant.id, gid);
  }

  const config = {
    alert: "updated",
    formId: "sg-mgmt-form-participant-edit",
    formUrl: urljoin(apiRoot, "/participants", `/${participantId}`),
    method: "PATCH",
    title: "Manage Participant Tickets"
  };

  const renderAssignedTickets = () => {
    const renderedTickets =  assignedTickets.map(pt =>
      <div className="mt-2 flex items-center justify-between" key={`assigned-ticket-${pt.gid}`}>
        <div>{pt.ticket_type.name}</div>
        <div>{renderCancelButton("Delete", () => {removeTicket(pt.id)})}</div>
      </div>
    );
    return renderedTickets;
  }

  const renderAllTickets = () => {
    const renderedTickets =  ticketTypes.data.ticket_types.map(tt =>
      <div className="mt-2 flex items-center justify-between" key={`assigned-ticket-${tt.gid}`}>
        <div>{tt.name}</div>
        <div>{renderButton("Assign", () => {assignTicket(tt.gid)})}</div>
      </div>
    );
    return renderedTickets;
  }

  const renderManageTicketsModal = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-modal-frame">
          <div className="sg-mgmt-modal-title">{config.title}</div>
          <div className="sg-mgmt-modal-content">
            <h2>{`${participant.individual.name_first} ${participant.individual.name_last}`}</h2>
            <div className="mt-4">
              <UiToggle
                flag={showAssignedTickets}
                label="Assigned Tickets"
                toggleFunc={() => {
                  setShowAssignedTickets(!showAssignedTickets);
                }}
              />
              {showAssignedTickets ? (
                renderAssignedTickets()
              ) : (
                <></>
              )}
            </div>
            <div className="mt-4">
              <UiToggle
                flag={showAllTickets}
                label="All Tickets"
                toggleFunc={() => {
                  setShowAllTickets(!showAllTickets);
                }}
              />
              {showAllTickets ? (
                renderAllTickets()
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <Loading />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Manage Tickets"
    >
      {renderManageTicketsModal()}
    </Modal>
  );
};

export default TicketingManageTicketsModal;

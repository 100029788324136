import React, { useContext, useRef, useState } from "react";

import { Checkbox } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import urljoin from "url-join";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import PeopleEventParticipantsModal from "@people/participants/PeopleEventParticipantsModal";
import TicketingManageTicketsModal from "@ticketing/tickets/TicketingManageTicketsModal";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import { useIndividuals } from "@shared/hooks/useIndividuals";
import {
  useAddParticipant,
  useParticipantsMeta,
  useParticipantsTable,
  useRefreshParticipants,
  useUpdateParticipant
} from "@shared/hooks/useParticipants";
import Loading from "@shared/Loading";

import MicroformWidgetSettings from "./MicroformWidgetSettings";

const RenderCheckBox = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  return (
    <Checkbox
      checked={value}
      value={value}
      variant="outlined"
      size="small"
      onChange={(e, newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
    />
  );
};

// TODO: Further reduce duplication between MicroformWidget and PeopleEventParticipantIndex
const MicroformWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const growlTableRef = useRef();
  const queryClient = useQueryClient();
  const enabled_metadata_fields = widget.widget_config.enabled_metadata_fields;
  const status_filter = widget.widget_config.microform.participantsByStatus;
  const [editParticipantId, setEditParticipantId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [manageTicketsParticipantId, setManageTicketsParticipantId] = useState(null);
  const [manageTicketsModalVisible, setManageTicketsModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const metaQuery = useParticipantsMeta(apiRoot, event.id);
  const participantsQuery = useParticipantsTable(apiRoot, event.id);
  const individualsQuery = useIndividuals(rootUrl);
  const addParticipant = useAddParticipant(event.id);
  const refreshParticipants = useRefreshParticipants(event.id);
  const updateParticipant = useUpdateParticipant(event.id);

  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const closeManageTicketsModal = () => {
    setManageTicketsModalVisible(false);
  };

  const resetManageTicketsModal = () => {
    closeManageTicketsModal();
    setManageTicketsParticipantId(null);
  };

  const filterParticipants = (participants) => {
    let filteredParticipants = participants;
    let statusesToKeep = [];
    Object.keys(status_filter).forEach((item) => {
      if (status_filter[item].selected) {
        statusesToKeep.push(item);
      }
    });
    filteredParticipants = filteredParticipants.filter((p) => statusesToKeep.includes(p.status) && !p.test_flag);
    return filteredParticipants;
  };

  const renderControls = () => {
    if (!editMode) return <></>;
    return <WidgetSettings label="Microform Settings" settingsComponent={MicroformWidgetSettings} />;
  };

  const columns = [
    {
      field: "override_name_first",
      headerName: "First Name",
      editable: true,
      valueGetter: (_value, row) => {
        return row?.name_first;
      },
      valueSetter: (value, row) => {
        row.name_first = value;
        return row;
      }
    },
    {
      field: "override_name_last",
      headerName: "Last Name",
      editable: true,
      valueGetter: (_value, row) => {
        return row?.name_last;
      },
      valueSetter: (value, row) => {
        row.name_last = value;
        return row;
      }
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200
    },
    {
      field: "override_company",
      headerName: "Company",
      editable: true,
      valueGetter: (_value, row) => {
        return row?.company;
      },
      valueSetter: (value, row) => {
        row.company = value;
        return row;
      }
    },
    {
      field: "type",
      headerName: "Participant Type",
      editable: true,
      type: "singleSelect",
      valueOptions: metaQuery.data.types.map((type) => type.name),
      valueGetter: (value) => {
        const str = value || "";
        return str;
      },
      valueSetter: (value, row) => {
        row.type = value;
        return row;
      }
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Preregistered", "Registered", "Cancelled", "Declined", "Disallowed"],
      valueGetter: (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      valueSetter: (value, row) => {
        row.status = value;
        return row;
      }
    },
    {
      field: "attended",
      headerName: "Attended",
      editable: true,
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      renderEditCell: (params) => <RenderCheckBox {...params} />,
      valueSetter: (value, row) => {
        row.attended = value;
        return row;
      }
    },
    metaQuery?.data?.metadataFields
      // enable if wanting to filter non-enabled fields from table
      // .filter((field) => enabled_metadata_fields[field.slug]?.selected)
      .sort((a, b) => a.sort_order - b.sort_order)
      .flatMap((mf) => ({
        field: mf.slug,
        headerName: mf.label,
        editable: true,
        valueGetter: (_value, row) => {
          return row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value;
        },
        valueSetter: (value, row) => {
          if (row.metadata.find((md) => md.field_slug == mf.slug) !== undefined) {
            row.metadata.find((md) => md.field_slug == mf.slug).value = value;
          } else {
            row.metadata.push({ field_slug: mf.slug, value: value });
          }
          return row;
        }
      })),
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => [renderEditAction(params.row.id), renderTicketAction(params.row.id)]
    }
  ].flat();

  const defaultColumnVisibility = Object.fromEntries([
    ["vault_saved_at", false],
    ...metaQuery.data.metadataFields.map((mf) => [mf.slug, false])
  ]);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const renderEditAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const manageTicketsClick = (id) => {
    setManageTicketsParticipantId(id);
    setManageTicketsModalVisible(true);
  };

  const renderTicketAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            manageTicketsClick(id);
          }}
        >
          Manage Tickets
        </span>
      </>
    );
  }

  const filteredIndividuals = () => {
    const participantIndividualIds = participantsQuery.data.participants.map((p) => p.individual_id);
    return individualsQuery.data.individuals.filter((ind) => !participantIndividualIds.includes(ind.id));
  };

  const addIndividual = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, individual) => {
      queryClient.setQueryData(["individuals"], (existing) => {
        const newIndividuals = [...existing.individuals, individual];
        return { individuals: newIndividuals };
      });
    }
  });

  const addIndividualCallback = (individual) => {
    addIndividual.mutate(individual);
    refreshParticipants.mutate();
  };

  const renderAddModal = () => (
    <PeopleEventParticipantsModal
      apiRoot={apiRoot}
      closeModal={closeAddModal}
      addIndividual={addIndividualCallback}
      individuals={filteredIndividuals()}
      metadataFields={metaQuery.data.metadataFields}
      regFields={metaQuery.data.regFields}
      modalVisible={addModalVisible}
      resetModal={resetAddModal}
      rootUrl={rootUrl}
      tags={metaQuery.data.tags}
      types={metaQuery.data.types}
      updateFunc={(participant) => {
        addParticipant.mutate({ participant: participant });
      }}
      enabledMetadataFields={enabled_metadata_fields}
      isMicroform={true}
      microformConfig={widget.widget_config}
    />
  );

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividualCallback}
        enabledMetadataFields={enabled_metadata_fields}
        individuals={[]}
        isMicroform={true}
        microformConfig={widget.widget_config}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metaQuery.data.metadataFields}
        regFields={metaQuery.data.regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={metaQuery.data.tags}
        types={metaQuery.data.types}
        updateFunc={(participant) => {
          updateParticipant.mutate({ participant: participant });
        }}
      />
    );
  };

  const renderManageTicketsModal = () => {
    if (!manageTicketsParticipantId) {
      return <></>;
    }
    return (
      <TicketingManageTicketsModal
        apiRoot={apiRoot}
        event={event}
        participantId={manageTicketsParticipantId}
        modalVisible={manageTicketsModalVisible}
        resetModal={resetManageTicketsModal}
        closeModal={closeManageTicketsModal}
      />
    )
  }

  const participantFields = ["attended", "override_name_first", "override_name_last", "override_company"];

  const saveCell = (params) => {
    let formUrl = urljoin(apiRoot, "/participants", `/${params.row.id}`);
    const formData = new FormData();
    formData.set("participant_gid", params.row.gid);
    if (participantFields.includes(params.field)) {
      formData.set(`participant[${params.field}]`, params.formattedValue);
    } else if (params.field === "status") {
      formData.set(`participant[${params.field}]`, params.formattedValue.toLowerCase());
    } else if (params.field === "type") {
      formData.set(
        `participant[event_participant_type_id]`,
        metaQuery.data.types.find((type) => type.name === params.formattedValue).id
      );
    } else {
      formData.set(`participant[metadata][${params.field}]`, params.formattedValue);
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formUrl,
      method: "PATCH",
      data: formData
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess(`Participant updated successfully`);
          updateParticipant.mutate(response.data.participant);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertError(error);
      });
  };

  const renderAddParticipantButton = () => {
    if (widget?.widget_config?.displayAddParticipant === "true") {
      return renderCreateButton("Add Participant", openAddModal);
    }
    return <></>;
  };

  if (participantsQuery.isPending || individualsQuery.isPending || metaQuery.isPending) {
    return <Loading />;
  }

  if (participantsQuery.isError || individualsQuery.isError || metaQuery.isError) {
    console.log(participantsQuery.error);
    console.log(individualsQuery.error);
    console.log(metaQuery.error);
    return <div>Error loading data. Please refresh and try again.</div>;
  }

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="mt-2">
        <div>{renderAddParticipantButton()}</div>
        <GrowlTable
          cellSelection={true}
          checkboxSelection
          onCellEditStop={saveCell}
          columns={columns}
          componentRef={growlTableRef}
          defaultColumnVisibility={defaultColumnVisibility}
          items={filterParticipants(participantsQuery.data.participants)}
          microform={true}
          tableName={`${widget.gid}-participants`}
          toolbarControls={[]}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      {renderAddModal()}
      {renderEditModal()}
      {renderManageTicketsModal()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default MicroformWidget;
